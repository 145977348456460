import {
  Component,
  OnInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
  DoCheck,
} from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import Chart from "chart.js";

import { AuthService } from "../../services/auth.service";
import { BehaviorSubject } from "rxjs";
import { NavbarService } from "./navbar.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit, DoCheck {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;

  public statsMenu: boolean = false;
  isAuthenticated: boolean = false;
  username: string;
  loggedInUsername: string;
  showorhidebackbtn: string;
  showbtn: boolean;

  public isUserLoggedIn: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  public isCollapsed = true;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute,
    private nav: NavbarService
  ) {
    this.location = location;
    this.sidebarVisible = false;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //     console.log(changes)
  // }

  ngDoCheck(): void {
    // console.log(this.location.path());
    this.showorhidebackbtn = this.location.path();
    if (this.showorhidebackbtn == "/dashboard") {
      this.showbtn = true;
    } else {
      this.showbtn = false;
    }
  }

  ngOnInit() {
    window.addEventListener("scroll", function () {
      navbarScroll();
    });
    function navbarScroll() {
      var y = window.scrollY;
      if (y > 10) {
        var header = document.getElementsByClassName("mainHead")[0];
        var header2 = document.getElementsByClassName("image1")[0];
        var header3 = document.getElementsByClassName("image2")[0];
        header.classList.add("small");
        header2.classList.add("small");
        header3.classList.add("small");
      } else if (y < 10) {
        var header = document.getElementsByClassName("mainHead")[0];
        var header2 = document.getElementsByClassName("image1")[0];
        var header3 = document.getElementsByClassName("image2")[0];
        header.classList.remove("small");
        header2.classList.remove("small");
        header3.classList.remove("small");
        // header.classList.remove('small');
      }
    }
    //checking user status
    this.auth.isUserLoggedIn.subscribe((val) => {
      this.isAuthenticated = val;
    });
    this.loggedInUsername = localStorage.getItem("userName");
    this.capitalizeUserName(this.loggedInUsername);

    if (this.loggedInUsername) {
      this.isAuthenticated = true;
    }

    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }

  capitalizeUserName(str) {
    let lowerCase = str.toLowerCase().trim();
    const nameArray = lowerCase.split(" ");
    const name = [];
    for (const n of nameArray) {
      name.push(n.replace(n[0], n[0]?.toUpperCase()));
    }

    this.username = name.join(" ");
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    // console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    const html = document.getElementsByTagName("html")[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }

    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const html = document.getElementsByTagName('html')[0];
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const html = document.getElementsByTagName("html")[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      html.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add("toggled");
      }, 430);

      var $layer = document.createElement("div");
      $layer.setAttribute("class", "close-layer");

      if (html.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (html.classList.contains("off-canvas-sidebar")) {
        document
          .getElementsByClassName("wrapper-full-page")[0]
          .appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function () {
        //asign a function
        html.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      html.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(2);
    }
    titlee = titlee.split("/").pop();

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  onLogout() {
    this.isUserLoggedIn.next(false);
    this.auth.userLogout();
    this.router.navigate(["/login"]);
  }

  toggle() {
    this.nav.showOrHide = !this.nav.showOrHide;
  }

  back() {
    this.location.back();
    // console.log(this.location, "location")
  }
}
